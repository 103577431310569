import type { VariantProps } from "./types.ts"

import * as React from "react"

import { cva } from "class-variance-authority"

/** Group used to align and space buttons consistently across the platform. */
export const ButtonGroup = React.forwardRef<
  HTMLSpanElement,
  VariantProps<typeof classForButtonGroup> & React.ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => {
  return (
    <span
      data-ui="button-group"
      className={classForButtonGroup({ className })}
      ref={ref}
      {...props}
    />
  )
})

ButtonGroup.displayName = "ButtonGroup"

export const classForButtonGroup = cva(["flex flex-wrap gap-2"], {
  variants: {
    justify: {
      end: "justify-end",
      start: "justify-start",
      between: "justify-between",
    },
  },
  defaultVariants: {
    justify: "end",
  },
})
